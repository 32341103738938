* {
    background-color: #282c34;
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin: auto 10vmin;
}

.left-para {
    text-align: left;
}

.App .logo {
    pointer-events: none;
    vertical-align: middle;
    margin-bottom: 10px;
    max-width: calc(10px + 6vmin);
}

.App .screenshots {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.App .screenshots > img {
    max-width: 100%;
    height: auto;
    padding: 10px;
}

li {
    padding: 1.5vmin;
}

a {
    color: #61dafb;
}